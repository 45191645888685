/* src/Pages/LandingPage.css */

header {
    background: rgba(0, 0, 0, 0.5);
    background-blend-mode: overlay;
}

header h1 {
    font-size: 3rem;
}

header p {
    font-size: 1.25rem;
}

.text-white {
    color: #fff;
}